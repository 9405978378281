<template>
	<div class="ums-organization">
		<div class="header">
			<a-breadcrumb style="margin-bottom: 20px">
				<a-breadcrumb-item>用户中心</a-breadcrumb-item>
				<a-breadcrumb-item>组织架构</a-breadcrumb-item>
			</a-breadcrumb>
			<a-row>
				<a-col :span="18">
					<a-button type="primary" @click="organizationCreateFormVisible = true">新增
					</a-button>
				</a-col>
				<a-col :span="6">
					<a-input-search v-model="dataSourceSearchInput" placeholder="请输入任何想搜索的内容" allowClear
						@search="handleDataSourceSearch" />
				</a-col>
			</a-row>
		</div>
		<div class="body">
			<a-table rowKey="id" size="small" bordered :data-source="getCurrentDataSourceList"
				:pagination="paginationOptions" :scroll="{ x: true }" :loading="dataSourceTableLoading"
				@change="handleTableChange">
				<a-table-column title="序号" data-index="index" align="center" width="60px">
					<template slot-scope="text, record, index">
						<span>{{ handleTableIndexCompute(index) }}</span>
					</template>
				</a-table-column>
				<a-table-column key="id" data-index="id" title="ID" width="200px" />
				<a-table-column key="number" data-index="number" title="组织编号" width="200px" />
				<a-table-column key="name" data-index="name" title="组织名称" width="150px" />
				<a-table-column key="parentId" data-index="parentId" title="上一级ID" width="200px" />
				<a-table-column key="remark" data-index="remark" title="备注" width="200px" />
				<a-table-column title="操作" data-index="operation" fixed="right">
					<template slot-scope="text, record">
						<a-button type="link" size="small"
							@click="dataSourceCurrent=record;organizationUpdateFormVisible=true;">
							资料修改
						</a-button>
						<a-popconfirm title="是否确定删除此组织机构？" ok-text="是" cancel-text="否"
							@confirm="handleDataSourceDelete(record)">
							<a href="#">删除</a>
						</a-popconfirm>
					</template>
				</a-table-column>
			</a-table>
		</div>
		<div class="footer"></div>

		<organization-create-form :visible="organizationCreateFormVisible"
			@onSuccess="handleOrganizationCreateFormSuccess" @onCancel="organizationCreateFormVisible = false" />

		<organization-update-form :visible="organizationUpdateFormVisible" :organization="dataSourceCurrent"
			@onSuccess="handleOrganizationUpdateFormSuccess" @onCancel="organizationUpdateFormVisible = false" />
	</div>
</template>

<script>
	import PaginationOptions from "@/options/pagination.js";
	import FiltrationOptions from "@/options/filtration.js";
	import UmsOrganizationApi from "@/api/ums/ums-organization.js";
	import OrganizationCreateForm from "./components/OrganizationCreateForm.vue";
	import OrganizationUpdateForm from "./components/OrganizationUpdateForm.vue";
	import {
		mapState
	} from "vuex";

	export default {
		name: "UmsOrganization",
		components: {
			OrganizationCreateForm,
			OrganizationUpdateForm
		},
		data() {
			return {
				paginationOptions: PaginationOptions,
				filtrationOptions: FiltrationOptions,
				queryType: "1",
				queryDate: [],
				dataSourceList: [],
				dataSourceCurrent: {},
				dataSourceSearchInput: "",
				dataSourceSearchList: [],
				dataSourceSearchValid: false,
				dataSourceTableLoading: false,
				organizationCreateFormVisible: false,
				organizationUpdateFormVisible: false
			};
		},
		computed: {
			...mapState({}),
			getCurrentDataSourceList: function() {
				return this.dataSourceSearchValid ?
					this.dataSourceSearchList :
					this.dataSourceList;
			}
		},
		watch: {
			dataSourceSearchInput: function(val) {
				if (val === null || val === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
				}
			}
		},
		mounted: function() {
			this.fetchOrganizationList();
		},
		methods: {
			fetchOrganizationList: function() {
				this.dataSourceTableLoading = true;
				UmsOrganizationApi.listAll().then((response) => {
					this.dataSourceTableLoading = false;
					if (response.status === "SUCCESS") {
						this.dataSourceList = response.body;
						if (this.dataSourceList.length === 0) this.$message.success("无数据");
						if (this.dataSourceSearchInput) this.handleDataSourceSearch(this
							.dataSourceSearchInput);
					} else {
						this.dataSourceList = [];
					}
				});
			},
			handleTableChange(pagination) {
				this.paginationOptions.current = pagination.current;
				this.paginationOptions.pageSize = pagination.pageSize;
			},
			handleTableIndexCompute(index) {
				const currentPage = this.paginationOptions.current - 1;
				const pageSize = this.paginationOptions.pageSize;
				return currentPage * pageSize + parseInt(index) + 1;
			},
			handleCreateBtnClick: function() {},
			handleOrganizationCreateFormSuccess: function(response) {
				this.organizationCreateFormVisible = false;
				this.fetchOrganizationList();
			},
			handleOrganizationUpdateFormSuccess: function(response) {
				this.organizationUpdateFormVisible = false;
				this.fetchOrganizationList();
			},
			handleDataSourceSearch: function(value) {
				if (value === null || value === "") {
					this.dataSourceSearchList = [];
					this.dataSourceSearchValid = false;
					return;
				}
				this.dataSourceSearchList = this.dataSourceList.filter((item) => {
					return JSON.stringify(item).indexOf(value.trim()) !== -1;
				});
				this.dataSourceSearchValid = true;
			},
			handleDataSourceDelete: function(record) {
				UmsOrganizationApi.deleteById(record.id).then(response => {
					if (response.status === "SUCCESS") {
						this.$message.success("删除成功！");
						this.fetchOrganizationList();
					}
				})
			}
		}
	};
</script>

<style lang="scss" scoped>
	.header {
		margin-bottom: 20px;
	}
</style>
