import http from "@/api/http.js";

function getParentTreeById(id) {
	return http({
		url: "/UmsOrganization/GetParentTreeById/" + id,
		method: "get"
	});
}

function listAll() {
	return http({
		url: "/UmsOrganization/ListAll",
		method: "get"
	});
}

function listTree() {
	return http({
		url: "/UmsOrganization/ListTree",
		method: "get"
	});
}

function create(payload) {
	return http({
		url: "/UmsOrganization/Create",
		method: "post",
		data: payload
	});
}

function update(payload) {
	return http({
		url: "/UmsOrganization/Update",
		method: "post",
		data: payload
	});
}

function deleteById(id) {
	return http({
		url: "/UmsOrganization/DeleteById/" + id,
		method: "post",
	});
}

export default {
	getParentTreeById,
	listAll,
	listTree,
	create,
	update,
	deleteById
};
