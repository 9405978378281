<template>
	<div class="organization-create-form">
		<a-modal :visible="visible" title="新增组织架构信息" @ok="handleModalOk" @cancel="handleModalCancel">
			<a-form layout="horizontal" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
				<a-form-item label="上级组织" :help="'组织编号：'+(organizationCurrent.number||'')">
					<a-cascader v-model="organizationCascaderValue" notFoundContent="无数据"
						:options="organizationTreeList"
						:field-names="{ label: 'name', value: 'id', children: 'children' }"
						:disabled="organizationCascaderLoading" change-on-select placeholder="请选择组织架构">
					</a-cascader>
				</a-form-item>
				<a-form-item label="组织编号">
					<a-input v-model="organizationNumberInput" placeholder="请填写组织编号" />
				</a-form-item>
				<a-form-item label="组织名称">
					<a-input v-model="organizationNameInput" placeholder="请填写组织名称" />
				</a-form-item>
				<a-form-item label="备注">
					<a-input v-model="remarkInput" placeholder="请填写备注" allow-clear />
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import UmsOrganizationApi from "@/api/ums/ums-organization.js";

	export default {
		name: "OrganizationCreateForm",
		props: {
			visible: Boolean
		},
		data() {
			return {
				organizationTreeList: [],
				organizationCascaderValue: [],
				organizationCascaderLoading: false,
				organizationCurrent: {},
				organizationNumberInput: "",
				organizationNameInput: "",
				remarkInput: ""
			};
		},
		watch: {
			visible: function(val, oldVal) {
				if (val) this.fetchOrganizationTree();
			},
			organizationCascaderValue: function(val, oldVal) {
				let organizationTree = this.organizationTreeList;
				if (val && val.length > 0) {
					val.forEach(item => {
						organizationTree.forEach(org => {
							if (item === org.id) {
								organizationTree = org.children;
								this.organizationCurrent = org;
							}
						});
					});
				} else {
					this.organizationCurrent = {};
				}
			},
			organizationCurrent: function(val, oldVal) {
				if (val) {
					this.organizationNumberInput = val.number;
				} else {
					this.organizationNumberInput = "";
				}
			}
		},
		mounted: function() {},
		methods: {
			fetchOrganizationTree: function() {
				this.organizationCascaderLoading = true;
				UmsOrganizationApi.listTree().then((response) => {
					this.organizationCascaderLoading = false;
					if (response.status === "SUCCESS") {
						this.organizationTreeList = response.body;
					} else {
						this.organizationTreeList = [];
					}
				});
			},
			handleModalOk: function() {
				const payload = {
					name: this.organizationNameInput,
					number: this.organizationNumberInput,
					parentId: this.organizationCurrent.id,
					remark: this.remarkInput
				};
				UmsOrganizationApi.create(payload).then((response) => {
					if (response.status === "SUCCESS") {
						this.$message.success(response.message);
						this.$emit("onSuccess", response);
						this.handleDataClear();
					}
				});
			},
			handleModalCancel: function() {
				this.$emit("onCancel");
				this.handleDataClear();
			},
			handleDataClear: function() {
				this.organizationCascaderValue = [];
				this.organizationCurrent = {};
				this.organizationNameInput = "";
				this.organizationNumberInput = "";
				this.remarkInput = "";
			}
		}
	};
</script>

<style lang="scss" scoped></style>
