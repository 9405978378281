<template>
  <div class="organization-update-form">
    <a-modal
      :visible="visible"
      title="修改组织架构信息"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-form
        layout="horizontal"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-item label="上级组织" :help="'上级编号：'+(organizationCurrent.number||'')">
          <a-cascader v-model="organizationCascaderValue"
                      notFoundContent="无数据"
                      placeholder="请选择组织架构"
                      change-on-select
                      :options="organizationTreeList"
                      :field-names="{ label: 'name', value: 'id', children: 'children' }"
                      :disabled="organizationCascaderLoading"
                      @change="handleCascaderChange">
          </a-cascader>
        </a-form-item>
        <a-form-item label="组织编号">
          <a-input v-model="organizationNumberInput" placeholder="请填写组织编号" allow-clear />
        </a-form-item>
        <a-form-item label="组织名称">
          <a-input v-model="organizationNameInput" placeholder="请填写组织名称" allow-clear />
        </a-form-item>
        <a-form-item label="备注">
          <a-input v-model="remarkInput" placeholder="请填写备注" allow-clear />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UmsOrganizationApi from "@/api/ums/ums-organization.js";

export default {
  name: "OrganizationCreateForm",
  props: {
    visible: Boolean,
    organization: Object
  },
  data() {
    return {
      organizationTreeList: [],
      organizationCascaderValue: [],
      organizationCascaderLoading: false,
      organizationCurrent: {},
      organizationNumberInput: "",
      organizationNameInput: "",
      remarkInput: ""
    };
  },
  watch: {
    visible: function(val, oldVal) {
      if (val) {
        this.fetchOrganizationTree();
        this.organizationNameInput = this.organization.name;
        this.organizationNumberInput = this.organization.number;
        this.remarkInput = this.organization.remark;
      }
    }
  },
  mounted: function() {
  },
  methods: {
    fetchOrganizationTree: function() {
      this.organizationCascaderLoading = true;
      UmsOrganizationApi.listTree().then((response) => {
        this.organizationCascaderLoading = false;
        if (response.status === "SUCCESS") {
          this.organizationTreeList = response.body;
          if (this.organization.parentId) {
            this.fetchOrganizationParentTree(this.organization.id);
          }
        } else {
          this.organizationTreeList = [];
        }
      });
    },
    fetchOrganizationParentTree: function(organizationId) {
      UmsOrganizationApi.getParentTreeById(organizationId).then((response) => {
        if (response.status === "SUCCESS" && response.body) {
          let organizationTree = response.body;
          do {
            this.organizationCascaderValue.push(organizationTree.id);
            if (organizationTree.children.length === 1) {
              this.organizationCurrent = organizationTree;
              organizationTree = organizationTree.children[0];
            }
          } while (organizationTree.children.length === 1);
        }
      });
    },
    handleCascaderChange: function(value, selectedOptions) {
      if (selectedOptions && selectedOptions.length > 0) {
        this.organizationCurrent = selectedOptions[selectedOptions.length - 1];
        this.organizationNumberInput = this.organizationCurrent.number;
      } else {
        this.organizationCurrent = {};
      }
    },
    handleModalOk: function() {
      const payload = {
        id: this.organization.id,
        name: this.organizationNameInput,
        number: this.organizationNumberInput,
        parentId: this.organizationCurrent.id,
        remark: this.remarkInput
      };
      UmsOrganizationApi.update(payload).then((response) => {
        if (response.status === "SUCCESS") {
          this.$message.success(response.message);
          this.$emit("onSuccess", response);
          this.handleDataClear();
        }
      });
    },
    handleModalCancel: function() {
      this.$emit("onCancel");
      this.handleDataClear();
    },
    handleDataClear: function() {
      this.organizationCascaderValue = [];
      this.organizationCurrent = {};
      this.organizationNameInput = "";
      this.organizationNumberInput = "";
      this.remarkInput = "";
    }
  }
};
</script>

<style lang="scss" scoped></style>
